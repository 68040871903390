import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import HeroImage2 from "../components/HeroImage/HeroImage2";
import AboutContent from "../components/About/AboutContent";

const About = () => {
    window.scrollTo(0, 0);
  return (
    <div>
      <Navbar />
      <HeroImage2
        heading="About"
        text="Read more details about me"
      />
      <AboutContent />
      <Footer />
    </div>
  );
};

export default About;
