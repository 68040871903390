import React from "react";
import "./Footer.css";
import {
  FaDiscord,
  FaHome,
  FaInstagram,
  FaLinkedin,
  FaMailBulk,
  FaPhone,
  FaTelegram,
  FaPaypal,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaHome size={20} style={{ color: "#fff", marginRight: "2rem" }} />
            <div>
              <p>Serbia, Priboj, 31330</p>
            </div>
          </div>
          <div class="phone">
            <h4>
              <FaPhone
                size={20}
                style={{ color: "#fff", marginRight: "2rem" }}
              />
              +(381) 60 448 4426
            </h4>
          </div>
          <div class="email">
            <h4>
              <FaMailBulk
                size={20}
                style={{ color: "#fff", marginRight: "2rem" }}
              />
              contact@drangula.net
            </h4>
          </div>
        </div>
        <div className="right">
          <h4>About the company</h4>
          <p>
			DRANGULA is a development company that creates futuristic projects using the latest technologies.
			Founder of the company is Dragan Milinkovic.
          </p>
          <div className="social">
            <Link to="https://discord.gg/JTtAB24b4s" target="_blank">
              <FaDiscord
                size={30}
                style={{ color: "#fff", marginRight: "1rem" }}
              />
            </Link>
            <Link to="https://instagram.com/drangulaa" target="_blank">
              <FaInstagram
                size={30}
                style={{ color: "#fff", marginRight: "1rem" }}
              />
            </Link>
            <Link to="https://www.linkedin.com/in/dragan-milinkovic-860a06255/" target="_blank">
              <FaLinkedin
                size={30}
                style={{ color: "#fff", marginRight: "1rem" }}
              />
            </Link>
            <Link to="https://t.me/drangula" target="_blank">
              <FaTelegram
                size={30}
                style={{ color: "#fff", marginRight: "1rem" }}
              />
            </Link>
            <Link to="https://paypal.me/drangulacompany" target="_blank">
              <FaPaypal
                size={30}
                style={{ color: "#fff", marginRight: "1rem" }}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
