import React from "react";

import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import HeroImage2 from "../components/HeroImage/HeroImage2";
import HostPricing from "../components/Pricing/HostPricing";

const Hosting = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Navbar />
      <HeroImage2
        heading="Bot Hosting"
        text="The best service to make your bot running smooth with 99.99% uptime!"
      />
      <HostPricing />
      <Footer />
    </div>
  );
};

export default Hosting;
