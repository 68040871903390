import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import HeroImage from "../components/HeroImage/HeroImage";
import Footer from "../components/Footer/Footer";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <HeroImage />
      <Footer />
    </div>
  );
};

export default Home;
