function sendEmail() {
  const form = document.getElementById("contact-form");
  let name = form.name.value;
  let email = form.email.value;
  let subject = form.subject.value;
  let message = 
`
Contact Form Details
- Name: ${name} / Email: ${email}
- Message: 

${form.message.value}
`;
    
  const mailtoLink =
    "mailto:contact@drangula.net?subject=" +
    encodeURIComponent(subject) +
    "&body=" +
    encodeURIComponent(message);

  window.location.href = mailtoLink;
}

export default sendEmail;
