const PricingData = [
  {
    name: "Basic (1 bot)",
    price: 9.99,
    cycle: 1,
    ram: "512MB",
    storage: "10GB",
    backups: 5,
    supports: "JavaScript/Python",
    panel: "Hosting panel included",
    support: "Support 24/5",
    planId: "P-954957250Y3776607MV26UNY",
  },
  {
    name: "Advanced (1 bot)",
    price: 14.99,
    cycle: 1,
    ram: "1GB",
    storage: "25GB",
    backups: 10,
    supports: "JavaScript/Python",
    panel: "Hosting panel included",
    support: "Support 24/5",
    planId: "P-8DV79399UA1124531MV26VUI",
  },
  {
    name: "Premium (1 bot)",
    price: 19.99,
    cycle: 1,
    ram: "2GB",
    storage: "50GB",
    backups: 25,
    supports: "JavaScript/Python",
    panel: "Hosting panel included",
    support: "Support 24/5",
    planId: "P-78W09057VL500002TMV26WEQ",
  },
  {
    name: "Basic (3 bots)",
    price: 24.99,
	discount: 17,
    cycle: 1,
    ram: "512MB",
    storage: "10GB",
    backups: 5,
    supports: "JavaScript/Python",
    panel: "Hosting panel included",
    support: "Support 24/5",
    planId: "P-3V885085CY166403LMWPYKSQ",
  },
  {
    name: "Advanced (3 bots)",
    price: 34.99,
    discount: 23,
    cycle: 1,
    ram: "1GB",
    storage: "25GB",
    backups: 10,
    supports: "JavaScript/Python",
    panel: "Hosting panel included",
    support: "Support 24/5",
    planId: "P-6TT644998G7455509MWPYP6Y",
  },
  {
    name: "Premium (3 bots)",
    price: 44.99,
    discount: 25,
    cycle: 1,
    ram: "2GB",
    storage: "50GB",
    backups: 25,
    supports: "JavaScript/Python",
    panel: "Hosting panel included",
    support: "Support 24/5",
    planId: "P-9UL65380KA059953VMWPYQ7A",
  },
];

export default PricingData;
