import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Hosting from "./routes/Hosting";
import PurchaseSuccess from "./routes/PurchaseSuccess";
import LoggingPage from "./routes/LoggingPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/bots" element={<BotsPage />} /> */}
        <Route path="/hosting" element={<Hosting />} />
        <Route path="/purchase-success" element={<PurchaseSuccess />} />
        <Route path="/logging-in" element={<LoggingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
