import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import HeroImage2 from "../components/HeroImage/HeroImage2";
import Footer from "../components/Footer/Footer";

const PurchaseSuccess = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <HeroImage2
        heading="Transaction completed"
        text="Thank you for your purchase!"
      />
      <Footer />
    </div>
  );
};

export default PurchaseSuccess;
