import "./Navbar.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import axios from "axios";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [color, setColor] = useState(false);
  const [user, setUser] = useState(null);

  const changeColor = () => {
    if (window.scrollY >= 50) {
      setColor(true);
    } else setColor(false);
  };

  let popup;

  const handleLogin = (event) => {
    event.preventDefault();
    const url = "https://drangula.net/auth/login";
    popup = window.open(url, "_blank", "width=500,height=500");

    const interval = setInterval(async function () {
      try {
        let response = await fetchUserData();

        if (popup.closed) return clearInterval(interval);

        if (response && response.username) {
          window.location.href = "https://drangula.net/";
          clearInterval(interval);
          popup.close();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }, 1000);
  };

  const fetchUserData = async () => {
    try {
      let response = await fetch("https://drangula.net/auth/user", {
        credentials: "include",
      });

      if (response.ok) {
        let userData = await response.json().catch(() => null);
        setUser(userData);
        return userData;
      } else {
        throw new Error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);

    if (!user) fetchUserData();

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("discordToken");
    axios
      .post("https://drangula.net/auth/logout", {}, { withCredentials: true })
      .then(console.log)
      .catch(console.log);
    setUser(null);
    window.location.href = "https://drangula.net/#logged-out";
  };

  return (
    <div className={color ? "header header-bg" : "header"}>
      <Link to="/">
        <h1>DRANGULA</h1>
      </Link>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link to="/hosting">
            <h1>Hosting</h1>
          </Link>
        </li>
        <li>
          <Link to="/about">
            <h1>About</h1>
          </Link>
        </li>
        <li>
          <Link to="/contact">
            <h1>Contact</h1>
          </Link>
        </li>
        {user ? (
          <li>
            <Link to="#" onClick={handleLogout} className="user-info">
              <h1>{user.username.toUpperCase()}</h1>
            </Link>
          </li>
        ) : (
          <li>
            <Link to="#" onClick={handleLogin}>
              <h1>Login with Discord</h1>
            </Link>
          </li>
        )}
      </ul>
      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: "#fff" }} />
        ) : (
          <FaBars size={20} style={{ color: "#fff" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;