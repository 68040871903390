import React, { useState, useEffect } from "react";

import "./PricingCard.css";
import {
  FaCloud,
  FaDatabase,
  FaGlobe,
  FaHeadphones,
  FaLaptop,
  FaMemory,
} from "react-icons/fa";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const HostPricingCard = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [, setColor] = useState(false);

  const onApprove = async (data, actions) => {
    await axios.post("https://drangula.net/api/subscriptions/", {
      body: JSON.stringify(data),
    });

    navigate(`/purchase-success`);
  };

  const changeColor = () => {
    if (window.scrollY >= 50) {
      setColor(true);
    } else setColor(false);
  };

  let popup;

  const handleLogin = (event) => {
    event.preventDefault();
    const url = "https://drangula.net/auth/login";
    popup = window.open(url, "_blank", "width=500,height=500");

    const interval = setInterval(async function () {
      try {
        let response = await fetchUserData();

        if (popup.closed) return clearInterval(interval);

        if (response && response.username) {
          window.location.href = "https://drangula.net/";
          clearInterval(interval);
          popup.close();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }, 1000);
  };

  const fetchUserData = async () => {
    try {
      let response = await fetch("https://drangula.net/auth/user", {
        credentials: "include",
      });

      if (response.ok) {
        let userData = await response.json().catch(() => null);
        setUser(userData);
        return userData;
      } else {
        throw new Error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);

    fetchUserData();

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  return (
    <div className="card">
      <h3> {props.name} </h3>
	  {props.discount ? (<h2 style={{color: "#63c2c4"}}> {props.discount}% OFF </h2>) : ("")}
      <span className="bar"></span>
      <p className="btc">
        ${props.price}/
        {props.cycle === 1 ? "month" : "every" + props.cycle + "months"}
      </p>
      <p>
        <FaGlobe /> {props.panel}
      </p>
      <p>
        <FaHeadphones /> {props.support}
      </p>
      <p>
        <FaLaptop /> {props.supports}
      </p>
      <p>
        <FaMemory /> RAM: {props.ram}
      </p>
      <p>
        <FaCloud /> Storage: {props.storage}
      </p>
      <p>
        <FaDatabase /> Backups: {props.backups}
      </p>
      {user ? (
        <PayPalScriptProvider
          className="btn"
          options={{
            clientId: PAYPAL_CLIENT_ID,
            intent: "subscription",
            vault: true,
          }}
        >
          <PayPalButtons
            style={{
/*              shape: "pill",
              color: "gold",
              layout: "horizontal",*/
                             shape: 'rect',
          color: 'black',
          layout: 'vertical',
          label: 'subscribe'
            }}
            createSubscription={async (data, actions) => {
              try {
                const response = await actions.subscription.create({
                  plan_id: props.planId,
                });
                console.log("Subscription Created:", response);
                return response;
              } catch (error) {
                console.error("Subscription Error:", error);
                return error;
              }
            }}
            onApprove={onApprove}
          />
        </PayPalScriptProvider>
      ) : (
        <Link onClick={handleLogin} className="btn">
          Log in to purchase
        </Link>
      )}
    </div>
  );
};

export default HostPricingCard;
