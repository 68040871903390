import React from "react";
import Navbar from "../components/Navbar/Navbar";
import HeroImage2 from "../components/HeroImage/HeroImage2";

const LoggingPage = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Navbar />
      <HeroImage2
        heading="Logging in..."
        text="This winod will be automatically closed!"
      />
    </div>
  );
};

export default LoggingPage;
