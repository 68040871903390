import React from "react";
import HostingData from "./HostPricingData";
import HostPricingCard from "./HostPricingCard";

const HostPricing = () => {
  return (
    <div className="pricing">
      <div className="card-container">
        {HostingData.map((data, index) => {
          return (
            <HostPricingCard
              planId={data.planId}
              name={data.name}
              price={data.price}
			  discount={data.discount}
              cycle={data.cycle}
              support={data.support}
              supports={data.supports}
              panel={data.panel}
              ram={data.ram}
              storage={data.storage}
              backups={data.backups}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HostPricing;
