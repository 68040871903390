import React from "react";

import "./AboutContent.css";
import { Link } from "react-router-dom";

const AboutContent = () => {
  return (
    <div className="about">
        <h1>Who am I?</h1>
        <p>
          Six years ago, I, Dragan Milinkovic from Serbia, embarked on a journey into the fascinating world of bot development for Discord. What began as a curiosity soon evolved into a passion, as I delved into the intricacies of crafting intelligent and efficient bots that could enhance the Discord experience for users.

          Over the years, my skills and enthusiasm for bot development have grown exponentially. I found joy in creating bots that not only performed tasks efficiently but also added a touch of innovation to Discord servers. The ability to bring automation, moderation, and entertainment to communities became my driving force.

          Throughout this journey, I've had the pleasure of working with over 800 satisfied customers. It's immensely gratifying to see the positive impact my bots have had on diverse Discord communities. The feedback and appreciation from users have been a constant source of motivation, encouraging me to continually refine and expand my bot development skills.

          As I, reflect on the past six years, I am grateful for the challenges and learning opportunities that have shaped my expertise. From the early days of experimentation to the development of sophisticated and feature-rich bots, the journey has been nothing short of exhilarating.

          Looking ahead, I am excited about the endless possibilities that bot development holds. Whether it's creating innovative features, improving user experiences, or adapting to the ever-evolving landscape of Discord, my commitment to delivering high-quality bots remains unwavering.

          Here's to the next chapter of this exciting journey, where I, Dragan Milinkovic, continue to push the boundaries of bot development and contribute to the vibrant and dynamic Discord community.
        </p>
        <Link to="/contact">
          <button className="btn">Contact</button>
        </Link>
    </div>
  );
};

export default AboutContent;
