import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import HeroImage2 from "../components/HeroImage/HeroImage2";
import Form from "../components/Form/Form";

const Contact = () => {
    window.scrollTo(0, 0);
  return (
    <div>
      <Navbar />
      <HeroImage2 heading="Contact" text="Have any questions? Contact me!" />
      <Form />
      <Footer />
    </div>
  );
};

export default Contact;
