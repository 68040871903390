import React from "react";

import "./Form.css";
import sendEmail from "./FormSubmit";

const Form = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
  };

  return (
    <div className="form">
      <form id="contact-form" onSubmit={handleSubmit}>
        <label>Your Name</label>
        <input required id="name" type="text"></input>
        <label>Email Address</label>
        <input required id="email" type="email"></input>
        <label>Subject</label>
        <input required id="subject" type="text"></input>
        <label>Message</label>
        <textarea
          required
          id="message"
          rows="6"
          placeholder="Type your message here"
        ></textarea>
        <button className="btn" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form;
